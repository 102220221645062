.font-size-sm {
	font-size: $font-size-sm;
}

.font-size-lg {
	font-size: $font-size-lg;
}

.font-weight-normal {
	font-weight: 400;
}

.font-weight-semibold {
	font-weight: 500;
}

@each $color, $value in $grays {
  @include text-emphasis-variant('.text-gray-#{$color}', $value, $green);
}
