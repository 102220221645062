.site-header {
	padding: 18px 0;
	z-index: $zindex-fixed;
	background-color: $white;

		a {
			display: block;
		}

		.pac {
			font-size: 20px;
			margin-right: 15px;
		}
}

@include media-breakpoint-up(md) {

		.site-header {
			font-size: 13px;
		}
}

@include media-breakpoint-up(lg) {

		.site-header {

				.main-menu {

						> li {

								+ li {
									margin-left: rem(34);
								}
						}
				}
		}

		.header-tools {

				> li + li {
					margin-left: rem(26);
				}
		}
}

@include media-breakpoint-between(sm, md) {

		.site-header {

				.main-menu {

						> li {

								+ li {
									margin-left: rem(10);
								}
						}
				}
		}

		.header-tools {

				> li + li {
					margin-left: rem(15);
				}
		}
}


@include media-breakpoint-only(sm) {

		.site-header {
			font-size: 12px;
		}
}