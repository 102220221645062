@each $font, $settings in $fonts {
	$family: map-get($settings, family);
	$weight: map-get($settings, weight);
  $style: map-get($settings, style);

		.#{$font} {
			font-family: $family !important;
			font-weight: $weight !important;
			font-style: $style !important;
		}
}

.text-white {

		@at-root a#{&} {
			
				@include hover {
					color: $green !important;
				}
		}
}

@include media-breakpoint-only(md) {

		html {
			font-size: 13px;
		}
}


@include media-breakpoint-down(sm) {

		html {
			font-size: 11px;
		}

		body {
			font-size: 14px;
		}

		h5, .h5 {
			font-size: 16px;
		}
}
