.partners {
	overflow: hidden;

		.h4 {
			line-height: 1;
		}
		
		.owl-stage,
		.owl-item,
		.item {
			height: 100%;
		}

		.item {
			display: flex;
			align-items: center;
		}

		figure {
			opacity: .8;
			padding-left: 10px;
			padding-right: 10px;
			transition: $default-transition;

				&:hover {
					opacity: 1;
				}
		}
}

@include media-breakpoint-up(sm) {

		.partners {

				div[class*="col"] {
					padding-top: rem(45);
					padding-bottom: rem(45);
				}

				.h4 {
					padding-right: rem(30);						

						strong {
							display: block;
							font-size: $h2-font-size;
							text-transform: uppercase;
						}
				}
		}

		.owl-controls-wrap {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			padding-bottom: 10px;
		}
}

@include media-breakpoint-down(xs) {

		.partners {

				div[class*="col"] {
					padding-top: 15px;
					padding-bottom: 15px;
				}

				.col-md-4 {
					background-color: $black;
				}

				.h4 {
					color: $yellow;
					font-size: $h2-font-size;
					text-transform: uppercase;

						strong {
							color: $white;
						}
				}
		}

		.owl-controls-wrap {
			margin-top: 15px;
		}
}