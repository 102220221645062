.carousel-slide  {
	position: relative;
	overflow: hidden;
	text-shadow: 0 2px 5px fade($black, 15);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

		ul {
			display: inline-block;

				li {
					position: relative;
					margin-bottom: 0;

						&:before {
							position: absolute;
							left: 0 !important;
							content: "\e902";
							font-family: 'pac' !important;
							color: $yellow;
						  speak: none;
						  font-style: normal;
						  font-weight: normal;
						  font-variant: normal;
						  text-transform: none;
						  line-height: 1;
						  -webkit-font-smoothing: antialiased;
						  -moz-osx-font-smoothing: grayscale;
						}

						+ li {
							margin-top: 10px;
						}
				}
		}
		
		.container {
			position: relative;
			z-index: 2;
		}
}

.carousel-slide-bg {
	overflow: hidden;
	width: 100%;
	height: 100%;
	@include element-center(tag);
	z-index: 1;

		img {
			max-width: none;
			@extend .mx-auto;

				@include media-breakpoint-up(sm) {
					width: auto;
					height: 100%;
				}
		}
}

.carousel-mouse {
	position: absolute;
	left: 0;
	right: 0;
	margin: auto;
	@include size(74px, 40px);
	z-index: 5;
}

@include media-breakpoint-up(sm) {

		.carousel-slide {
			padding: rem(110) 0;

				.h1 {
					margin-bottom: rem(20);
				}

				h3 {
					margin-bottom: rem(30);
				}

				ul {

						li {
							padding-left: rem(40);

								&:before {
									font-size: rem(23); 
								}
						}
				}

				.btn {
					margin-top: rem(70);
				}
		}

		.carousel-logo {
			margin-bottom: rem(55);
		}

		.carousel-img {
			position: relative;
			display: inline-block;
			box-shadow: 0 5px 25px fade($black, 30);

				+ .carousel-img {
					margin-top: rem(-200);
					left: rem(200);
				}

				&:hover {
					z-index: 2;
				}
		}

		.carousel-mouse {
			bottom: rem(35);
		}
}

@include media-breakpoint-up(md) {

		.carousel-slide {
			font-size: rem(18);

				ul {

						li {

								&:before {
									top: rem(5);
								}
						}
				}
		}

		.carousel-img {

				+ .carousel-img {
					margin-top: rem(-200);
					left: rem(200);
				}
		}
}

@include media-breakpoint-down(sm) {

		.carousel-slide {

				ul {

						li {

								&:before {
									top: rem(5, 14);
								}
						}
				}
		}
}

@include media-breakpoint-only(sm) {

		.carousel-img {

				+ .carousel-img {
					margin-top: rem(40);
					left: rem(100);
				}
		}
}

@include media-breakpoint-down(xs) {

		.carousel-slide {
			padding: 30px 0 100px;
			text-align: center;

				.h1 {
					margin-bottom: 15px;
				}

				h3 {
					margin-bottom: 25px;
				}

				ul {
					text-align: left;

						li {
							padding-left: 30px;
						}
				}

				.btn {
					margin-top: 30px;
				}
		}

		.carousel-logo {
			margin-bottom: 30px;
		}

		.carousel-mouse {
			bottom: 25px;
		}

		.carousel-img {
			display: none;
		}
}