// @each $key, $value in $bg-colors {
//   .bg-#{$key} {
//     background-color: $value !important;
//   }
// }


@each $key, $lengths in $bg-gradients {
	$start-color: map-get($lengths, start-color);
	$end-color: map-get($lengths, end-color);
	$start-percent: map-get($lengths, start-percent);
	$end-percent: map-get($lengths, end-percent);

	.gradient-x-#{$key} {
		@include gradient-x($start-color, $end-color, $start-percent, $end-percent);
	}
}
