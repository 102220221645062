

.container-lg {
	@extend .mx-auto;
	width: 100%;
	max-width: 1400px;
}

.container-fullhd {
	@extend .mx-auto;
	width: 100%;
	max-width: 1920px;
}