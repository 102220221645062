@mixin element-center ( $type: tag ) {
  position: absolute;
  top: 0;
  bottom: 0;
  @if $type == tag {
    left: 0;
    right: 0;
  } @else if $type == image {
    left: -9999px;
    right: -9999px;
  }
  margin: auto;
}