

.form-control {
	font-size: 16px;
}

textarea.form-control {
	height: 235px;
}

.form-group-icon {
	position: relative;
		
		.form-control {
			padding-left: 45px;
		}

		br {
			display: none;
		}
}

.form-control-icon {
	position: absolute;
	top: 0;
	left: 0;
	@extend .d-flex;
	@extend .justify-content-center;
	@extend .align-items-center;
	@include size(45px, 54px);
	color: #7a7a7a;
	font-size: 14px;
}

.form-submit {
	margin-top: 35px;
}

.screen-reader-response {
	@extend .d-none;
}

.wpcf7-not-valid-tip {
	color: $red;
	font-size: 13px;
}

.wpcf7-response-output {
	font-weight: $font-weight-bold;
	text-align: center;
}

.wpcf7-validation-errors {
	color: $red;
}

.wpcf7-mail-sent-ok {
	color: $green;
}