
.projects {
	position: relative;

		.col-md-3 {
			position: relative;
			border: 1px solid $black;
		}

		.btn-dots {
			position: absolute;
			left: 0;
			right: 0;
			margin: auto;
			z-index: 4;
		}
		
		.thumb-square {

				&:hover {

						img {
							opacity: .35;
						}

						.project-active {
							opacity: 1;
						}
				}
		}
}

.projects-filter {

		button {
			position: relative;

				&:not(.active) + button:before {
					content: '';
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					margin: auto;
					background-color: $black;
				}

				&.active {
					color: $white;
					background-color: $green;
					box-shadow: 0 3px 5px fade($black, 15);

						&:after {
							content: '';
							@include size(0);
							position: absolute;
							top: 100%;
							left: -1px;
							right: 0;
							margin: auto;
						}
				}
		}
}

.project-active {
	@include element-center;
	opacity: 0;
	z-index: 2;
	transition: $default-transition;

		.h2 {
			width: 100%;
			position: relative;

				&:before,
				&:after {
					content: '';
					position: absolute;
					left: 0;
					right: 0;
					margin: auto;
					width: rem(200);
					background-color: $yellow;
				}

				&:before {
					top: 0;
				}

				&:after {
					bottom: 0;
				}
		}
}

@include media-breakpoint-up(sm) {

		.partners-module {
			padding-top: rem(70);

				figure {
					margin-bottom: rem(70);
				}
		}

		.projects {

				.btn-dots {
					bottom: rem(70);
				}
		}

		.projects-filter {
			margin-bottom: rem(70);

				button {
					position: relative;
					padding: 10px rem(20);

						&:not(.active) {

								+ button:before {
									@include size(2px, rem(16));
								}
						}

						&.active {

								&:before {
									@include size(0 !important);
								}

								&:after {
									border-top: 10px solid $green;
									border-right: 10px solid transparent;
									border-left: 10px solid transparent;
								}
						}
				}
		}

		.project-active {
			padding: rem(30);

				.h2 {
					padding: rem(30) 0;

						&:before,
						&:after {
							height: 4px;
						}
				}

				.btn {
					width: rem(200);
					margin-top: rem(50);
				}
		}
}

@include media-breakpoint-down(xs) {

		.partners-module {
			padding-top: 30px;

				figure {
					margin-bottom: 30px;
				}
		}

		.projects {

				.btn-dots {
					bottom: 30px;
				}
		}

		.projects-filter {
			margin-bottom: 30px;

				button {
					padding: 7px;
				}
		}

		.project-active {
			padding: 15px;

				.h2 {
					padding: 20px 0;

						&:before,
						&:after {
							height: 2px;
						}
				}

				.btn {
					width: 160px;
					margin-top: 25px;
				}
		}
}

@media (min-width: 500px) and (max-width: map-get($grid-breakpoints, sm) - 1) {
	
		.projects {

				.col-12 {
					flex: 0 0 50%;
					max-width: 50%;
				}
		}
}