@import "variables";

@font-face {
  font-family: 'pac';
  src:
    url('#{$font-path}/pac.ttf?6mymij') format('truetype'),
    url('#{$font-path}/pac.woff?6mymij') format('woff'),
    url('#{$font-path}/pac.svg?6mymij#pac') format('svg');
  font-weight: normal;
  font-style: normal;
}

.pac {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'pac' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pac-1 {
  &:before {
    content: $pac-1;
  }
}
.pac-2 {
  &:before {
    content: $pac-2;
  }
}
.pac-3 {
  &:before {
    content: $pac-3;
  }
}
.pac-4 {
  &:before {
    content: $pac-4;
  }
}
.pac-5 {
  &:before {
    content: $pac-5;
  }
}
.pac-6 {
  &:before {
    content: $pac-6;
  }
}
.pac-7 {
  &:before {
    content: $pac-7;
  }
}
.pac-8 {
  &:before {
    content: $pac-8;
  }
}
.pac-9 {
  &:before {
    content: $pac-9;
  }
}
.pac-10 {
  &:before {
    content: $pac-10;
  }
}
.pac-11 {
  &:before {
    content: $pac-11;
  }
}
.pac-12 {
  &:before {
    content: $pac-12;
  }
}
.pac-13 {
  &:before {
    content: $pac-13;
  }
}
.pac-14 {
  &:before {
    content: $pac-14;
  }
}
.pac-15 {
  &:before {
    content: $pac-15;
  }
}
.pac-16 {
  &:before {
    content: $pac-16;
  }
}
.pac-17 {
  &:before {
    content: $pac-17;
  }
}
.pac-18 {
  &:before {
    content: $pac-18;
  }
}