*,
*:before,
*:after {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {

    a {
      display: block;
    }
}

h1, h2, h3, h4, h5, h6, p, ol, ul,
blockquote, figure, label {
  margin-bottom: 0;
}

input, select, textarea, button {
  padding: 0;
  border: none;
  background: none;
  appearance: none;

    &:focus {
      outline: none;
    }
}

img:not(.lazyload) {
  display: inline-block;
  @include img-fluid();
}

ul,
ol {
  @include list-unstyled();
}

b,
strong {
  font-weight: bold;
}

em, i {
  font-style: italic;
}

a,
button,
input[type="submit"],
input[type="reset"] {
  cursor: pointer;
  transition: $default-transition;

    &:focus {
      outline: none;
    }
}

a {
  color: $link-color;
  text-decoration: $link-decoration;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;

    @include hover {
      color: $link-hover-color;
      text-decoration: $link-hover-decoration;
    }
}

button {
  color: $link-color;
  text-decoration: $link-decoration;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;

    @include hover {
      color: $link-hover-color;
    }
}

p {

    &:last-child {
      margin-bottom: 0;
    }

    a {

        &:hover {
          text-decoration: underline;
        }
    }
}

.mw-none {
  max-width: none !important;
}