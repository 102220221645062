
.page-header {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	text-shadow: 0 2px 15px fade($black, 25);

		.btn {
			text-shadow: none;
		}
}

@include media-breakpoint-up(sm) {

		.page-header {
			height: rem(370);

				.h1,
				h1,
				.entry-content {
					margin-bottom: rem(40);
				}
		}

		.page-title {
			padding-top: rem(50);
		}
}

@include media-breakpoint-down(xs) {

		.page-header {
			padding: 30px 0;

				.h1,
				h1,
				.entry-content {
					margin-bottom: 20px;
				}
		}

		.page-title {
			padding-top: 30px;
		}
}