.module-contacts {
	
		h3, h4 {
			font-family: $font-family-base;
		}

		h4 {
			font-weight: 400;
		}

		.map {
			border: 1px solid fade($black, 15);
			box-shadow: 0 3px 15px fade($black, 25);
		}

		.icon {
			padding-right: 15px;
		}

		.pac-2 {
			font-size: rem(40);
		}

		.pac-10 {
			font-size: rem(60);
		}

		.pac-11 {
			font-size: rem(45);
		}

		.media {
			font-weight: 400;
		}
}

.module-contacts-form {
	
		.row {
			margin-left: -6px;
			margin-right: -6px;
		}

		div[class*="col-"] {
			padding-left: 6px;
			padding-right: 6px;
		}

		.btn {
			width: 250px;
		}
}

@include media-breakpoint-up(sm) {

		.module-contacts {

				h3 {
					margin-bottom: rem(45);
				}

				.map {

						&:before {
							padding-top: percentage( 340 / 570 );
						}
				}

				.icon {
					width: 70px;
				}
		}

		.contact-item {

				+ .contact-item {
					margin-top: rem(75);
				}
		}

		.module-contacts-form {

				h3 {
					margin-bottom: rem(50);
				}
		}
}

@include media-breakpoint-up(md) {

		.module-contacts {

				.media + .media{
					margin-top: rem(40);
				}
		}
	
		.contact-item {

				.order-md-2 {
					padding-left: rem(70);
				}
		}
}

@include media-breakpoint-down(sm) {

		.module-contacts {

				.media {
					margin-bottom: 30px;
				}
		}
}

@include media-breakpoint-down(xs) {

		.module-contacts {

				h3 {
					margin-bottom: rem(45);
				}

				.map {

						&:before {
							padding-top: 320px;
						}
				}

				.icon {
					width: 50px;
				}
		}

		.contact-item {

				+ .contact-item {
					margin-top: 50px;
				}
		}

		.module-contacts-form {

				h3 {
					margin-bottom: 25px;
				}
		}
}