.clients {
	position: relative;

		.header-section {
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			z-index: 2;
			@include gradient-y($black, transparent, 0, 100%);
		}
}

#map,
#clients-map {
	height: rem(900);
}

.client-tooltip {
	width: 300px;
	max-height: 300px;
	overflow: auto;
	line-height: 1.4;

		.head {
			margin-bottom: 15px;
		}

		.entry-content {
			font-size: 12px;
		}

		.foot {
			margin-top: 15px;
			font-size: 14px;
			font-weight: $font-weight-bold;

				p + p {
					margin-top: 10px;
				}
		}

		.pac {
			font-size: 16px;
			margin-right: 10px;
		}
}