
.about {
	position: relative;
	background: url('#{$images-img}home-about.jpg') left center no-repeat;

		&:after {
			content: '';
			@include element-center;
			z-index: 1;
		}

		.container {
			position: relative;
			z-index: 2;
		}

		h1 {
			font-family: $font-family-base;
		}
}


@include media-breakpoint-up(sm) {

		.about {
			padding: rem(65) 0 rem(90);
			background-size: auto 100%;

				h1 {
					margin-bottom: rem(-30);
					font-size: rem(50);
					color: fade($black, 5);
				}

				.h1 {
					margin-bottom: rem(80);
				}

				.btn {
					margin-top: rem(65);
				}
		}
}

@include media-breakpoint-up(md) {

		.about {

				&:after {
					@include gradient-x(transparent, $gray-100, 16%, 42%);
				}
		}
}

@include media-breakpoint-only(sm) {

		.about {

				&:after {
					@include gradient-x(transparent, $gray-100, 10%, 35%);
				}
		}
}

@include media-breakpoint-down(xs) {

		.about {
			padding: 30px 0;
			text-align: center;
			background-size: cover;

				&:after {
					background-color: fade($white, 85);
				}

				h1 {
					margin-bottom: -18px;
					font-size: 25px;
					color: fade($black, 10);
				}

				.h1 {
					margin-bottom: 25px;
				}

				.btn {
					margin-top: 25px;
				}
		}
}