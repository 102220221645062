

.services {
	overflow: hidden;

		.row {
			margin-left: -8px;
			margin-right: -8px;
		}

		.col-12 {
			padding-left: 8px;
			padding-right: 8px;
		}

		.thumb {
			box-shadow: 0 3px 7px fade($black, 25);

				&:hover {
					color: $white;
					box-shadow: 0 3px 25px fade($black, 40);

						.h4 {
							background-color: $black;
						}
				}
		}

		.h4 {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			z-index: 2;
			font-size: $h5-font-size;
			background-color: $yellow;
			transition: background 250ms ease-in-out;
		}
}

@include media-breakpoint-up(sm) {

		.services {
			padding-bottom: rem(70);

				.h4 {
					height: rem(60);
					padding-left: rem(25);
					padding-right: rem(25);
				}
		}
}

@include media-breakpoint-down(sm) {

		.services {

				.container-lg {
					max-width: map-get($container-max-widths, sm);
				}

				.col-12 {
					margin-bottom: 16px;
				}
		}
}

@include media-breakpoint-down(xs) {

		.services {
			padding-bottom: 30px;

				.container-lg {
					max-width: 354px;
				}

				.h4 {
					height: 50px;
					padding-left: 15px;
					padding-right: 15px;
				}
		}
}