
.modal-content {
	position: relative;
}

.modal-close {
	position: absolute;
	top: 0;
	right: 0;
	@include size(30px);

		&:before,
		&:after {
			content: '';
			@include element-center;
			background-color: $black;
		}

		&:before {
			transform: rotate(-45deg);
		}

		&:after {
			transform: rotate(45deg);
		}

		&:hover {

				&:before,
				&:after {
					background-color: $green;
				}
		}
}

@include media-breakpoint-up(sm) {

		.modal-close {
			@include size(rem(70));

				&:before,
				&:after {
					@include size(rem(36), 2px);
				}
		}
}

@include media-breakpoint-down(xs) {

		.modal-close {
			@include size(30px);

				&:before,
				&:after {
					@include size(22px, 2px);
				}
		}
}