$white:  #fff;
$gray-100: #f5f5f5;
$gray-400: #999;
$gray-500: #666;
$gray-600: #777;
$black:  #000;

$grays: (
	600: $gray-600
);

$blue:    #007bff;
$red:     #dc3545;
$yellow:  #ffeb3b;
$green:   #65d656;

$colors: (
  yellow: $yellow,
  green: $green
);

$theme-colors: (
  primary: $blue,
  success: $green,
  warning: $yellow,
  danger: $red,
  gray: $gray-100
);

$btn-colors: (
  black: (
    background: $black,
    border: $black,
    active-background: $yellow,
    active-border: $yellow
  ),
  white: (
    background: $white,
    border: $white,
    active-background: $green,
    active-border: $green
  ),
  warning: (
    background: $yellow,
    border: $yellow,
    active-background: $green,
    active-border: $green
  )
);

$bg-gradients: (
  black-success: (
    start-color: $black,
    end-color: $green,
    start-percent: 50%,
    end-percent: 50%,
  )
);

$enable-print-styles: false;

$link-color: inherit;
$link-hover-color: darken($green, 15%);
$link-hover-decoration: none;


$enable-shadows: true;

$body-color: $black;

$spacer: 0;

$grid-breakpoints: (
  xs: 0,
  sm: 768px,
  md: 992px,
  lg: 1230px
);

$container-max-widths: (
  sm: 720px,
  md: 960px,
  lg: 1200px
);

$font-family-sans-serif: 'Ubuntu', sans-serif;
$font-weight-normal: 300;

$font-size-lg: rem(22);
$font-size-sm: 14px;
$line-height-base: ( 28 / 16 );

$h1-font-size: rem(45);
$h2-font-size: rem(30);
$h3-font-size: rem(25);
$h4-font-size: rem(20);
$h5-font-size: rem(18);
$h6-font-size: 16px;

$headings-font-family: 'Play', sans-serif;
$headings-font-weight: 700;

$border-radius:    0;
$border-radius-lg: 0;
$border-radius-sm: 0;


// Buttons
$input-btn-padding-y: rem(17);
$input-btn-padding-x: rem(40);
$btn-font-size-base: rem(20);
$input-btn-line-height: 1;

$input-btn-padding-y-sm: 12px;
$input-btn-padding-x-sm: 30px;
$btn-font-size-sm: 14px;
$input-btn-line-height-sm: $input-btn-line-height;

$input-btn-padding-y-lg: rem(23);
$input-btn-padding-x-lg: rem(58);
$btn-font-size-lg: $font-size-lg;
$input-btn-line-height-lg:  $input-btn-line-height;

$btn-font-weight: 700;
$btn-box-shadow: 0 3px 5px fade($black, 15);
$btn-focus-box-shadow: $btn-box-shadow;
$btn-active-box-shadow: $btn-box-shadow;

$btn-transition: $default-transition;

$btn-color-gray: #000;

$btn-border-width: 3px;

// Forms
$input-btn-padding-y: 17px;
$input-btn-padding-x: 17px;
$input-btn-line-height: 1;
$input-color: $black;
$input-border-color: #ccc;
$input-box-shadow: none;
$input-placeholder-color: $gray-400;
$input-focus-box-shadow: 0 3px 10px fade($blue, 25);
$form-group-margin-bottom: 12px;

// Modals
$modal-lg: 1600px;
$modal-content-box-shadow-sm-up: 0 5px 25px fade($black, 75);
$modal-backdrop-opacity: .65;


// Reboots
$input-height-inner: 10px;
$input-height-inner-sm: 10px;
$input-height-inner-lg: 10px;