

.team-item {

		&:nth-child(odd) {
			background-color: fade($black, 5);
		}

		.thumb {
			box-shadow: 0 3px 10px fade($black, 30);
			background-color: transparent;
		}

		h3 {
			margin-bottom: 10px;
		}

		h5 {
			margin-bottom: 30px;
		}
}

.team-img {
	max-width: 400px;
}

@include media-breakpoint-up(sm) {

		.team-item {
			padding-top: rem(70);
			padding-bottom: rem(70);
		}
}

@include media-breakpoint-down(xs) {

		.team-item {
			padding-top: 30px;
			padding-bottom: 30px;

				h3,
				h5 {
					text-align: center;
				}
		}

		.team-img {
			margin-bottom: 30px;
		}
}