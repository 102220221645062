
.pac {
	@extend .align-middle;
}

// Default Transition
.df {
	transition: $default-transition;
}

ul {

		a {
			@include gradient-y(transparent, $link-hover-color, calc(100% - 1px), 1px);
			 background-repeat: no-repeat;
		  background-size: 0% 100%;

				&:hover {
					background-size: 100% 100%;
				}
		}
}

.owl-carousel {

		.owl-item img {
			width: auto;
			display: inline-block;
		}
}

.owl-controls {
	position: relative;
	padding: 0 20px;

		.owl-next,
		.owl-prev {
			position: absolute;
			top: 0;

				&:hover {
					color: $yellow;
				}
		}

		.owl-prev  {
			left: 0;
		}

		.owl-next {
			right: 0;
		}
}

.owl-dots {
	font-size: 0;
	padding: 4px 20px;
	@extend .list-inline;
}

.owl-dot {
	@extend .list-inline-item;
	@extend .rounded-circle;
	@include size(8px);
	cursor: pointer;
	background-color: $white;
	transition: $default-transition;

		+ .owl-dot {
			margin-left: 20px;
		}

		&:hover,
		&.active {
			background-color: $yellow;
		}

		span {
			display: none;
		}
}

.owl-nav {
	font-size: 0;

		.pac {
			font-size: 14px;
		}

		.disabled {
			display: none;
		}
}

@include media-breakpoint-up(sm) {

		.header-section {
			padding: rem(75) 0;
		}
}

@include media-breakpoint-down(xs) {

		.header-section {
			padding: 30px 0;
		}
}