.site-navbar {

		.main-menu {

				a {
					@include gradient-y(transparent, $yellow, calc(100% - 1px), 1px);
					background-repeat: no-repeat;
				}

				.current-menu-item a,
				a:hover {
					color: $yellow;
				}
		}
}

@include media-breakpoint-up(md) {

		.site-navbar {

				div[class*="col"] {
					padding-top: rem(27);
					padding-bottom: rem(27);
				}

				.main-menu {

						> li {

								+ li {
									margin-left: rem(25);
								}
						}
				}
		}
}

@include media-breakpoint-only(sm) {
		
		.site-navbar {
			font-size: 13px;

				.main-menu {

						> li {

								+ li {
									margin-left: rem(20);
								}
						}
				}
		}
}

@include media-breakpoint-down(sm) {

		.site-navbar {
			position: relative;
			font-size: 15px;

				.collapse,
				.collapsing {
					position: absolute;
					top: 100%;
					left: 0;
					right: 0;
					z-index: $zindex-fixed;
				}

				.main-menu {
					padding: 20px 0;
				}
		}

		.btn-menu {
			margin-top: 10px;
			margin-bottom: 10px;
			@include button-menu(40px, 28px, 4px, 8px, $white, $yellow, 0);
		}
}

@include media-breakpoint-down(xs) {

		.site-navbar {

				.main-menu {
					display: block;
					text-align: center;

						li + li {
							margin-top: 20px;
						}
				}
		}
}