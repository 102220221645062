
.footer-content {
	padding-top: rem(50);
	color: $white;

		ul {
			text-transform: uppercase;
			color: $gray-600;
			font-size: 14px;

				li + li {
					margin-top: 5px;
				}
		}
}

.widget_address {
	font-weight: 500;
		
		> p {
			line-height: (20 / 16);
			text-transform: uppercase;
		}

		.contacts {

				p + p {
					margin-top: 20px;
				}
		}

		.pac {
			display: inline-block;
			text-align: center;
			width: 20px;
			margin-right: 10px;
			font-size: 20px;
		}

		a:hover {
			color: $yellow;
		}
}

.widget_contact {

		p {
			margin-bottom: 25px;
			font-style: italic;
		}
}

.copyrights {
	padding: rem(20) 0;
	background-color: #333;
}



@include media-breakpoint-up(sm) {

		.footer-content {

				.h5 {
					cursor: default;
					margin-bottom: rem(30);
				}
		}

		.widget_address {

				> p {
					margin-bottom: rem(50);
				}
		}
}

@include media-breakpoint-up(md) {

		.footer-content {
			padding-bottom: rem(50);
		}
}

@include media-breakpoint-up(lg) {

		.widget_address {
			padding-left: 15px;
		}
}

@include media-breakpoint-down(sm) {

		.footer-content {
			
				.col-12 {
					padding-bottom: rem(50);
				}
		}
}

@include media-breakpoint-down(xs) {

		.footer-content {
			padding-top: rem(50);
			background: $black;
			text-align: center;

				.h5 {
					margin-bottom: rem(30);
				}
			
				.col-12 {
					padding-bottom: rem(50);
				}
		}

		.widget_address {

				> p {
					margin-bottom: 15px;
				}
		}
}