.project-dialog {
  max-width: 1600px;
}

.project-content {
	font-size: 14px;
	min-height: rem(300);
	line-height: ( 26 / 16 );

		&.loading {
			background: $white url("#{$images-icons}loader.gif") center no-repeat;
		}

		h3 {
			font-family: $font-family-base;
		}
}

.pr-controls {
	
		button {
			width: 30px;
			font-size: rem(30);

				&:hover {
					color: $green;
				}
		}
}

.pr-slides {
	color: $gray-500;

		strong {
			color: $black;
		}
}


@include media-breakpoint-up(sm) {

		.project-content {
			padding: rem(70);

				.row {
					margin-left: rem(-30);
					margin-right: rem(-30);
				}

				.col-12 {
					padding-left: rem(30);
					padding-right: rem(30);
				}

				h3 {
					margin-bottom: rem(30);
				}

				.options {
					padding-top: rem(30);

						figure {
							margin-right: 15px;
						}
				}
		}

		.pr-controls {
			margin-top: rem(40);

				button {
					color: #d1d1d1;
				}

				.owl-next {
					margin-left: rem(20);
				}
		}

		.pr-slides {
			margin-bottom: rem(45);
			font-size: 20px;

				strong {
					font-size: rem(45);
				}
		}
}

@include media-breakpoint-down(xs) {

		.project-content {
			padding: 30px 0 0;

				h3 {
					margin-bottom: 20px;
					text-align: center;
				}

				.col-sm-4 {
					margin-bottom: 30px;
					padding-left: 30px;
					padding-right: 30px;
				}
		}

		.pr-controls {

				button {
					position: absolute;
					top: 0;
					bottom: 0;
					margin: auto;
					font-size: 30px;
					z-index: 2;

						&.disabled {
							display: none;
						}
				}
		}

		.owl-prev {
			left: 15px;
		}

		.owl-next {
			right: 15px;
		}

		.pr-slides {
			margin-bottom: rem(45);
			font-size: 20px;

				strong {
					font-size: rem(45);
				}
		}
}