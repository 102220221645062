 
.thumb {
	display: block;
	position: relative;
	max-width: 100%;
	height: 0;
	overflow: hidden;
	text-align: center;
	background-color: $black;
	transition: $default-transition;

		img {
			@include element-center(image);
			max-width: none;
			width: auto;
			height: 101%;
			backface-visibility: hidden;
			transition: $default-transition;
		}

		@at-root a#{&}, button#{&} {

				&:hover img {
					transform: scale(1.04);
				}
		}

		a, button {

				&:hover img {
					transform: scale(1.04);
				}
		}
}


// $thumb-sizes: (
// 	(100, 100),
// );

// @each $width, $height in $thumb-sizes {
// 	.thumb-#{$width}x#{$height} {
// 		width: $width * 1px;
// 		height: $height * 1px;
// 	}
// }

$thumb-sizes: (
	square: (
		w: 100%,
		h: 100%
	),
	post: (
		w: 100%,
		h: 62.163%
	)
);

@each $name, $sizes in $thumb-sizes {
	$width: map-get($sizes, w);
  $height: map-get($sizes, h);

		.thumb-#{$name} {
			width: $width;
			padding-bottom: $height;
		}
}