.entry-title {
	font-family: inherit;
	line-height: ( 24 / 17 );

		&.h5 {
			margin-bottom: 15px;
		}

		&.h3 {
			font-family: $font-family-base;
			font-weight: 500;
			margin-bottom: 30px;
		}
}

.post-featured {
	margin-bottom: 10px;
}

.post-meta {
	padding: 10px 0;
	font-size: 14px;

		.pac {
			margin-right: 10px;
		}

		.pac-6 {
			font-size: 18px;
		}

		li + li {
			margin-left: 20px;
		}
}

.alignnone {
  margin-left: 0;
  margin-right: 0;
}
.aligncenter {
  display: block !important;
  @extend .mx-auto;
}
.alignleft,
.alignright {
  margin-bottom: rem(20);
}

.entry-content {

		h1, h2, h3, h4, h5, h6 {
			margin-bottom: rem(30);

				&:last-child {
					margin-bottom: 0;
				}
		}

		p, li, ul, ol, div, figure {
			margin-bottom: rem(20);

				&:last-child {
					margin-bottom: 0;
				}
		}

		ul {

				li {
					position: relative;

						&:before {
							position: absolute;
							left: 0;
							content: "\e902";
							font-family: 'pac' !important;
							color: $green;
						  speak: none;
						  font-style: normal;
						  font-weight: normal;
						  font-variant: normal;
						  text-transform: none;
						  line-height: 1;
						  -webkit-font-smoothing: antialiased;
						  -moz-osx-font-smoothing: grayscale;
						}
				}
		}

		ol {
			list-style: decimal;
		}
		
		.wp-caption,
		figure {
			display: inline-block;
			position: relative;
				
				.wp-caption-text,
				figcaption {
					position: absolute;
					left: 0;
					bottom: 0;
					right: 0;
					z-index: 2;
					color: $white;
					font-weight: 400;
					padding: rem(15) rem(30);
					background-color: fade($black, 70);
				}
		}

		img {
			box-shadow: 0 3px 5px fade($black, 30);
		}

		strong {
			font-weight: 500;
		}

		a {
			color: $green;
			text-decoration: underline;

				&:hover {
					color: darken($green, 15);
				}
		}
}

.post-footer {
	margin-top: 20px;
}

.alm-btn-wrap {
	text-align: center;
}

.alm-reveal {
	@extend .row;

		.post-item {
			@extend .col-sm-6;
			@extend .col-12;
		}
}

.post-loader {
	@include size(64px);
	background: url('#{$images-icons}loader.gif');

		&.done {
			display: none;
		}
}

@include media-breakpoint-up(sm) {

		.post-article {
			padding-top: rem(70);
		}

		.post-item {
			margin-bottom: 50px;
				
				.entry-title,
				.entry-content {
					overflow: hidden
				}

				.entry-content {
					height: 72px;
				}
		}

	  .alignleft {
	    float: left;
	    margin-right: rem(15);
	  }

	  .alignright {
	    float: right;
	    margin-left: rem(15);
	  }

		.entry-content {

				ul {

						li {
							padding-left: rem(60);

								&:before {
									font-size: rem(20);
									top: rem(6);
									left: rem(20);
								}
						}
				}

				ol {
					padding-left: rem(35);
				}
		}
}


@include media-breakpoint-up(md) {

		.post-item {
		
				.entry-title {
					height: rem(50);
				}
		}
}

@include media-breakpoint-only(sm) {

		.post-item {
		
				.entry-title {
					height: 44px;
				}
		}
}

@include media-breakpoint-down(xs) {
		
		.post-article {
			padding-top: 30px;
		}

		.post-item {
			margin-bottom: 30px;
		}

		.entry-content {

				ul {

						li {
							padding-left: 30px;

								&:before {
									font-size: 18px;
									top: 4px;
									left: 0;
								}
						}
				}

				ol {
					padding-left: 15px;
				}
		}
}