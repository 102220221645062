.widget {

		&:last-child {
			margin-bottom: 0;
		}
}

.widget_title  {
	margin-bottom: 25px;
}

.widget_search {

		form {
			position: relative;
		}

		.form-control-icon {

				&:hover {
					color: $black;
				}
		}

		.pac {
			font-size: 18px;
		}
}

.popular-posts {

		li {
			@extend .media;

				+ li {
					margin-top: 30px;
				}
		}

		a {
			background-image: none;
		}

		a:not(.wpp-post-title) {
			@extend .thumb;
			width: 70px;
			height: 70px;
			background-image: none;
		}

		.wpp-post-title {
			font-size: 16px;
			line-height: (22 / 16);
			font-family: $font-family-base;
			font-weight: 500;
			padding-left: 20px;
			@extend .media-body;
		}
}

.widget_categories,
.widget_archive {

		li {
			position: relative;
			padding-left: 35px;
			text-transform: uppercase;
			font-weight: 400;

				+ li {
					margin-top: 10px;
				}
		}
}

.widget_categories {

		li {
			background: url("#{$images-icons}w-cat-i.svg") 3px 3px no-repeat;
		}
}

.widget_archive {

		li {
			background: url("#{$images-icons}w-arch-i.svg") 3px 2px no-repeat;
		}
}

@include media-breakpoint-up(md) {
		
		.sidebar {
			flex: 0 0 rem(360);
			max-width: rem(360);
		}
}

@include media-breakpoint-up(sm) {

		.sidebar {
			padding-bottom: rem(70);
		}

		.widget {
			margin-bottom: rem(40);
		}
}

@include media-breakpoint-only(sm) {
		
		.popular-posts,
		.widget_categories,
		.widget_archive {
			float: left;
		}
		
		.popular-posts {
			width: 40%;
			padding-right: 10px;

				h3 {
					font-size: 13px;
				}
		}

		.widget_categories,
		.widget_archive {
			font-size: 13px;
		}

		.widget_categories {
			width: 35%;
			padding-left: 10px;
			padding-right: 10px;
		}

		.widget_archive {
			width: 25%;
			padding-left: 10px;
		}
}

@include media-breakpoint-down(xs) {

		.sidebar {
			padding-bottom: 30px;
		}

		.widget {
			margin-bottom: 30px;
		}
}