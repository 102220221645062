.btn {
	@extend .text-uppercase;
	text-shadow: none;
	border: $btn-border-width solid transparent;
	@include button-size($input-btn-padding-y, $input-btn-padding-x, $btn-font-size-base, $input-btn-line-height, $btn-border-radius);
}


@each $color, $value in $btn-colors {
	$background: map-get($value, background);
  $border: map-get($value, border);
  $active-background: map-get($value, active-background);
  $active-border: map-get($value, active-border);

	  .btn-#{$color} {
	    @include button-variant($background, $border, $active-background, $active-border);
	  }
}

@each $color, $value in $btn-colors {
  $background: map-get($value, background);
  $border: map-get($value, border);
  $active-background: map-get($value, active-background);
  $active-border: map-get($value, active-border);
  
  .btn-outline-#{$color} {
    @include button-outline-variant($border);
  }
}

.btn-lg {
  @include button-size($input-btn-padding-y-lg, $input-btn-padding-x-lg, $font-size-lg, $input-btn-line-height-lg, $btn-border-radius-lg);
}

.btn-sm {
  border-width: 1px;
  @include button-size($input-btn-padding-y-sm, $input-btn-padding-x-sm, $btn-font-size-sm, $input-btn-line-height, $btn-border-radius-sm);
}

.btn-dots {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  @include size(60px);
  background-color: $green;
  @extend .rounded-circle;

    span,
    &:before,
    &:after {
      @include element-center;
      @include size(7px);
      border-radius: 50%;
      background-color: $yellow;
      transform: $default-transition;
    }

    &:before,
    &:after {
      content: '';
    }

    &:before {
      transform: translate(-13px);
    }

    &:after {
      transform: translateX(13px);
    }

    &:hover {
      background-color: $yellow;

        span,
        &:before,
        &:after {
          background-color: $green;
        }
    }
}